/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@fortawesome/fontawesome-pro/css/all.css";
@import "../node_modules/material-design-iconic-font/dist/css/material-design-iconic-font.min.css";

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';

@media screen {
       .modal-xxl .modal-lg {
           width: 90% !important;
           max-width: calc(90%);
       }

       .modal-xxl .modal-content {
           max-width: 100%;
           width: 100%;
       }
}

.ng-invalid.ng-touched {
  border-color: #ea4335;
}

.ng-valid.ng-touched {
  border-color: #34a853;
}

@media print {
  body { background-color: #ffffff; }
}
body { background-color: #ffffff; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

/*.form-control {*/
/*  padding: 0.375rem 0.75rem !important;*/
/*}*/

.tz-eastern {
  color: #FFFFFF;
  background-color: #b39ddb;
  border: transparent;
}

.tz-central {
  color: #FFFFFF;
  background-color: #a5d6a7;
  border: transparent;
}

.tz-mountain {
  color: #FFFFFF;
  background-color: #ffe082;
  border: transparent;
}

.tz-pacific {
  color: #FFFFFF;
  background-color: #81d4fa;
  border: transparent;
}
